import styled, { css } from 'styled-components';
import { GroupCardFlightsProps } from './GroupCardFlights';

export const GridElement = styled.div<Omit<GroupCardFlightsProps, 'data'>>`
  ${({ theme, ...props }) => {
    return css`
      display: grid;
      grid-template-rows: auto 1fr;
      gap: ${theme.spacing.base};

      // 576px
      @media screen and (min-width: ${theme.breakpoint.sm}) {
        grid-template-columns: repeat(4, 1fr);
        gap: ${theme.spacing.md};
      }

      // 768px
      @media screen and (min-width: ${theme.breakpoint.sm}) and (max-width: ${theme.breakpoint.lg}) {
        grid-template-columns: repeat(2, 1fr);
        padding: ${theme.spacing.base};
      }
    `;
  }};
`;
