import styled, { css } from 'styled-components';
import { BadgeProps } from './Badge';

export const Element = styled.span.withConfig({
  shouldForwardProp: (prop) => !['appearence', 'color', 'size'].includes(prop),
})<BadgeProps>`
  ${({ theme, appearence, color, size, ...props }) => {
    return css`
      border-radius: ${theme.radius.md};
      padding: ${theme.spacing.xxs} ${theme.spacing.sm};
      font-size: ${theme.typography.font_size.text_sm};
      display: flex;
      align-items: center;

      gap: 0.125rem;

      // Primary
      ${appearence === 'default' &&
      css`
        color: ${theme.color.white};
        background: ${theme.color.secondary_500};
      `}

      // Secondary
      ${appearence === 'secondary' &&
      css`
        color: ${theme.color.white};
        background: ${theme.color.secondary_400};
      `}

      // Size sm
      ${size === 'sm' &&
      css`
        //height: ${theme.spacing.base}; // FIXME
        font-size: ${theme.typography.font_size.text_xs};
      `};

      // Size base
      ${size === 'base' &&
      css`
        height: calc(${theme.spacing.base} + ${theme.spacing.sm});
        font-size: ${theme.typography.font_size.text_sm};
      `};

      // Size large
      ${size === 'lg' &&
      css`
        height: ${theme.spacing.md};
        font-size: ${theme.typography.font_size.text_base};
      `};

      // Color
      ${color &&
      css`
        ${() => {
          switch (color) {
            case 'dark':
              return css`
                color: ${theme.color.primary_500};
              `;
            case 'white':
              return css`
                color: ${theme.color.dark};
              `;
            default:
              return css`
                color: ${theme.color.white};
              `;
          }
        }};

        background-color: ${theme.color[color]};
      `};
    `;
  }};
`;
