import styled, { css } from 'styled-components';
import { imageBlurredFadeIn } from '@/components/image/imageLoadedFadeIn.styles';
import { imagePulse } from '@/components/image/imagePulse.styles';

export const ImageContainer = styled.div<{
  minHeight: number | string;
  minHeightMobile?: string | number;
}>`
  ${({ theme, minHeight, minHeightMobile, ...props }) => {
    return css`
      position: relative;
      text-align: center;

      border-top-right-radius: ${theme.radius.sm};
      border-top-left-radius: ${theme.radius.sm};
      overflow: hidden;

      min-height: ${minHeight}px;
      ${minHeightMobile &&
      css`
        @media screen and (max-width: ${theme.breakpoint.sm}) {
          min-height: ${minHeightMobile}px;
        }
      `}

      background: ${theme.color.primary_500};
      margin: 0;

      // Pulse Effect
      ${imagePulse};

      img {
        object-fit: cover;
        // Blur Effect
        ${imageBlurredFadeIn};
        // Override opacity to 1 as it was before applying the interpolation above
        &.img-loaded {
          //opacity: 1 !important;
        }
      }

      > a {
        &:hover {
          img {
            opacity: 0.4;
            scale: 1.125;
          }
        }
      }
    `;
  }};
`;
