import React, { ReactNode } from 'react';
import { GridElement } from './GroupCardFlights.styles';
import CardFlight from '@/features/home/components/CardFlight/CardFlight';

export interface GroupCardFlightsProps {
  data: [FeaturedFlight];
  className?: string;
  children?: ReactNode;
}

const GroupCardFlights = ({ data, children, ...props }: GroupCardFlightsProps) => {
  return (
    <GridElement {...props}>
      {data.slice(0, 4).map((featuredFlight, index) => (
        <CardFlight key={`i-${index}`} flightOffer={featuredFlight} />
      ))}
    </GridElement>
  );
};

export default GroupCardFlights;
