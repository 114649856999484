import React from 'react';
import { ImageContainer } from './CardImage.styles';
import Image from '@/providers/Image';
import Link from '@/providers/Link';
import useImagePulse from '@/components/image/imagePulseHook';

export interface CardImageProps {
  href?: string;
  image: Pick<ImageObjType, 'title' | 'imageUrl' | 'blurDataUrl'>;
  minHeight?: number | string;
  minHeightMobile?: number | string;
  priority?: boolean;
}

const CardImage = ({ href, image, minHeight = 168, minHeightMobile, priority, ...props }: CardImageProps) => {
  const { className, containerRef, handleOnCompleted } = useImagePulse();

  // TODO ADD A FALLBACK IMAGE
  if (!image) return null;

  return (
    <ImageContainer
      minHeight={minHeight}
      minHeightMobile={minHeight}
      className={className}
      ref={containerRef}
      {...props}
    >
      {(href && (
        <Link href={href}>
          <Image
            src={`${image.imageUrl}`}
            alt={image.title}
            fill
            placeholder={`data:image/jpeg;base64,${image.blurDataUrl}`}
            fadeInOnBlur
            handleOnCompleted={handleOnCompleted}
            priority={priority}
          />
        </Link>
      )) || <Image src={`${image.imageUrl}`} alt="" fill priority={false} />}
    </ImageContainer>
  );
};

export default CardImage;
