import React, { ReactNode } from 'react';
import { DefaultTheme } from 'styled-components';
import { Element } from './Badge.styles';

export interface BadgeProps {
  appearence?: 'default' | 'secondary';
  color?: keyof DefaultTheme['color'];
  size?: 'sm' | 'base' | 'lg';
  className?: string;
  children?: ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void | undefined;
}

const Badge = ({ appearence = 'default', color, children, ...props }: BadgeProps) => {
  return (
    <Element appearence={appearence} color={color} {...props}>
      {children}
    </Element>
  );
};

export default Badge;
