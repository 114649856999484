import styled, { css } from 'styled-components';

export const Element = styled.div`
  ${({ theme, ...props }) => {
    return css`
      border-radius: ${theme.radius.sm};
      background: ${theme.color.white};
      box-shadow: ${theme.box_shadow.base};
    `;
  }};
`;

export const CardHeader = styled.div`
  ${({ theme, ...props }) => {
    return css`
      position: relative;
      // Not the CardImage element, but the badge container
      > div:nth-child(2) {
        position: absolute;
        top: ${theme.spacing.base};
        left: ${theme.spacing.base};
        display: flex;
        gap: ${theme.spacing.sm};
      }
    `;
  }};
`;

export const CardTitle = styled.div`
  ${({ theme, ...props }) => {
    return css`
      margin-bottom: ${theme.spacing.base};
    `;
  }};
`;

export const CardBody = styled.div`
  ${({ theme, ...props }) => {
    return css`
      padding: ${theme.spacing.base};
    `;
  }};
`;
export const CardFooter = styled.div`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
      justify-content: space-between;
      gap: ${theme.spacing.base};
      padding: ${theme.spacing.base};
    `;
  }};
`;
